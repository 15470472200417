<template>
  <header>
    <a target="_blank" href="https://www.rangercamp.cz">
      <img src="/logo.png" alt="RangerCamp logo" />
    </a>
    <input type="text" name="search" placeholder="Hledat..." v-model="$store.state.filters.searchQuery" />
    <button class="showFilters" @click="toggleFilters" title="Show filters"><i class="fas fa-filter"></i></button>
    <button class="showMap" @click="toggleMap" title="Show map"><i class="fas fa-map"></i></button>
    <button class="refresh" @click="refresh" title="refresh"><i class="fas fa-sync"></i></button>
    <div :class="{ filters: true, hidden: filtersHidden }">
      <div v-for="filter in filters" :key="filter.key" class="filter">
        <input :id="`f_${filter.key}`" type="checkbox" :checked="filter.value" @change="toggleFilter(filter)" />
        <label :for="`f_${filter.key}`">{{ filter.name }}</label>
      </div>
    </div>
  </header>
</template>

<script>
import { getFilter } from '@/store/localization'

export default {
  name: 'topPanel',
  data: () => ({
    filtersHidden: true,
  }),
  computed: {
    filters() {
      var filters = Object.keys(this.$store.state.filters).map(key => ({ key, value: this.$store.state.filters[key] }))
      var filtered = filters.filter(f => f.key != 'searchQuery')
      var result = filtered.map(f => ({ ...f, name: getFilter(f.key) }))
      return result;
    }
  },
  methods: {
    toggleFilters() {
      this.filtersHidden = !this.filtersHidden;
    },
    toggleFilter(filter) {
      this.$store.commit('changeFilter', { key: filter.key, value: !filter.value });
    },
    toggleMap() {
      this.$store.commit('toggleMap');
    },
    refresh() {
      this.$store.dispatch('load');
    },
  },
}
</script>

<style lang="scss">
@import '../constants.scss';

header {
  display: grid;
  width: 100%;

  grid-auto-flow: row;
  justify-content: stretch;
  align-content: stretch;
  grid-template-rows: $header-height min-content;
  grid-template-columns: min-content 1fr min-content min-content min-content;

  background-color: #000;

  img {
    height: 100%;
  }

  & > input {
    box-sizing: border-box;
    padding: $padding;
  }

  button {
    height: $header-height;
    width: $header-height;

    color: inherit;
    background-color: inherit;
    font-size: 1.2rem;
  }

  .filters {
    display: grid;
    grid-column: 1 / 6;
    grid-row: 2;
    padding: $padding;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-gap: $padding;
    overflow-x: auto;
    // justify-content: center; // cut's start filters on small screen

    &.hidden {
      display: none;
    }

    .filter {
      display: grid;
      grid-auto-flow: column;
      grid-gap: $padding;
      padding: $padding;
      cursor: pointer;
      color: $box-text-color;
      background-color: $box-back-color;
      border-radius: $padding;

      input {
        position: relative;
        
        width: 1rem;
        height: 1rem;
        margin-right: .75rem;
        
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        background-color: transparent;

        &:before {
          content: '';
          
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          
          width: 100%;
          height: 100%;
          
          background-color: transparent;
          border: 2px solid #aaaaaa;
          
          transition: all 0.3s ease-in-out;
        }
        &:checked:before {
          height: 50%;
          
          transform: rotate(-45deg);
          
          border-width: 4px;
          border-color: #4caf50;
          border-top-style: none;
          border-right-style: none;
        }
      }
    }
  }
}
</style>